import React, { useState } from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/SEO/seo';
import FlowLogo from '../assets/images/flow-logo-alt.svg';
import banner from '../assets/images/webinar/masterclass-replay-cover.png';
import Social from '../components/Social';
import YouTube from 'react-youtube';
import { YouTubePlayer } from 'youtube-player/dist/types';
import Dialog from '../components/Dialog';
import FlowPoster from '../components/blog/FlowPoster';

const MasterclassReplay = (page: PageProps) => {
  const [player, setPlayer] = useState<null | YouTubePlayer>(null);

  const changeTime = (seconds: number) => {
    player?.seekTo(seconds, true);
    player?.playVideo();
  };

  return (
    <>
      <Seo page={page} banner={banner} title="Flow Masterclass" />
      <main className="page-flow-masterclass-replay">
        <section className="section-1">
          <div className="container masterclass-container">
            <div className="section-1__content">
              <img src={FlowLogo} alt="flow" className="section-1__logo" />
              <h1 className="section-1__title u-text-primary">
                Profit First automatiseren met Flow
              </h1>
              <div className="section-1__subtitle">
                Ontdek hoe je je geldstromen duidelijk inzichtelijk maakt en automatisch kan verdelen, 
                zodat je langetermijndoelen haalbaar worden.
              </div>
              <div className="section-1__text u-color-lavender">
                <p>
                  Geld zou je leven makkelijker moeten maken, in plaats van moeilijker. Flow verandert 
                  jouw relatie met geld door je direct controle te geven. Flow kan automatisch je 
                  geld verdelen in potjes zodat je altijd, tot op de cent, weet waar je financieel aan 
                  toe bent.
                </p>
                <p>
                  Ook Profit First werkt met dit potjesprincipe. Het is een complete methode, inclusief 
                  welke rekeningen je nodig hebt en welke percentages de juiste zijn voor je bedrijf. 
                  Met de methode verander je je denkwijze: inkomsten - winst = kosten. Je richt je op 
                  winst en leert tegelijk je kosten te begroten.
                </p>
                <p>
                  Door de samenwerking tussen Flow en Profit First, hoef je het verdelen van het geld 
                  niet meer handmatig te doen. Je kunt je hele Profit First methode nu automatiseren 
                  met Flow. Dit is perfect voor bedrijven die geen negatieve financiële verrassingen 
                  willen, maar wel grip willen hebben op hun financiën en zich willen concentreren 
                  op de groei van hun bedrijf.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="section-2">
          <div className="container masterclass-container">
            <div className="section-2__content">
              <YouTube
                videoId="iX-W3XC1aBE"
                onReady={(e) => setPlayer(e.target)}
                className="section-2__iframe"
              />
              <h2 className="section-2__title">Timestamps</h2>
              <div className="section-2__list">
                {[[0, 'Voorwoord'],
                  [143, 'Voorwoord Mike Michalowicz'],
                  [362, 'Wat is Profit First'],
                  [1860, 'Profit First automatiseren met Flow'],
                  [4381, 'Tijd voor vragen'],
                  [4699, 'Samenwerken met je boekhouder']
                  ].map(([timestamp, label]) => { 
                  const seconds = Math.floor(timestamp % 3600 % 60);
                  const minutes = Math.floor(timestamp % 3600 / 60);
                  const hours = Math.floor(timestamp / 3600);
                  return (
                    <button className="section-2__item" onClick={() => changeTime(timestamp)}>
                      <strong>{hours > 0 ? hours + ":" : ""}{String(minutes).padStart(2, "0")}:{String(seconds).padStart(2, "0")}</strong>{' '}<span>{label}</span>
                    </button>
                  )})}
              </div>
            </div>
          </div>
        </section>
        <section className="section-3 u-section-padding-sm">
          <div className="container">
            <FlowPoster extended />
          </div>
        </section>
        <footer className="masterclass-footer">
          <div className="container masterclass-container">
            <div className="masterclass-footer__content">
              <div className="masterclass-footer__copyright">
                Copyright 2021 Flow Money Automation B.V.
              </div>
              <Social className="masterclass-footer__social" extended />
            </div>
          </div>
        </footer>
        <Dialog />
      </main>
    </>
  );
};

export default MasterclassReplay;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
